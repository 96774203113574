.paragraph::first-letter {
    font-size: 150%;
    color: #000000;
  }
  .placement-con {
    background-color: #f3dcd4;
    margin-bottom: 50px;
    margin-top: 20px;
    padding-bottom: 100px;
    padding-top: 50px;
  }
  .stars{
    color: #f39c12;
  }