.mission-card, .vision-card {
    border: none;
    transition: background-color 0.3s;
    cursor: pointer; 
    text-align: justify;
}

.mission-card:hover, .vision-card:hover {
    background-color: #0078C9; 
    color:#FFFFFF;
    text-align: justify;
    font-weight: bold;
}

.selected {
    background-color: #0078C9 !important;
}
.text-wrap {
    white-space: normal; 
    word-wrap: break-word;
    word-break: break-word; 
    overflow-wrap: break-word;
    text-align: left;
  }
  .text-wrap:hover {
    background-color:#0078C9; 
    color:#FFFFFF;
    text-align: justify;
    font-weight: bold;
  }
  .check-icon {
    color: #F05A28;
  }
  .student-text {
    text-align: justify;
  }