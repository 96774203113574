.dashboard {
    display: flex;
  }
  
  .navbar-custom {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #343a40;
  }
  
  .menu-btn {
    margin-right: 10px;
    color: #ffffff !important;
  }
  
  .sidebar {
    width: 250px;
    background-color: #343a40;
    position: fixed;
    height: 100%;
    top: 56px; /* height of the navbar */
    left: 0;
    transition: all 0.3s;
    z-index: 999;
  }
  
  .sidebar.collapsed {
    width: 80px;
  }
  
  .sidebar .tabtext {
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    text-decoration: none;
  }
  
  .sidebar .tabtext .icon-dashboard {
    margin-right: 10px;
  }
  
  .sidebar .tabtext .text {
    margin-left: 10px;
  }
  
  .sidebar .tabtext:hover {
    background-color: #495057;
  }
  
  .sidebar .tabtext.active {
    /* background-color: #87CEFA; */
    background: linear-gradient(109.6deg, rgb(185, 212, 242) 11.2%, rgb(244, 210, 226) 100.3%);
    color: rgb(0, 0, 0);
    border-radius: 12px;
  }
  
  .sidebar.open .tabtext {
    padding: 10px 20px;
  }
  
  .sidebar.collapsed .tabtext {
    justify-content: center;
  }
  
  .content {
    margin-left: 250px;
    transition: all 0.3s;
    padding-top: 56px; /* height of the navbar */
    width: calc(100% - 250px);
  }
  
  .content.sidebar-closed {
    margin-left: 80px;
    width: calc(100% - 80px);
  }
  
  @media (max-width: 768px) {
    .sidebar {
      width: 70%;
      left: -100%;
    }
  
    .sidebar.open {
      left: 0;
    }
  
    .content {
      margin-left: 0;
      width: 100%;
    }
  
    .content.sidebar-closed {
      margin-left: 0;
      width: 100%;
    }
  }
  