.enquiry-btn {
    background-color: #F05A28 !important;
    color: #FFFFFF;
}
.about-head {
    color: #F05A28;
    font-weight: bold;
}
.head1 {
    color: #0078C9 ;
}
.text-primary{
    color: #F05A28 !important;
}
.btn-outline-primary {
    color: #000000 !important;
    background-color:#F05A28 !important ;
}
.icon1{
    background-color: #F05A28 !important;
}
.booknow {
    background-color: #0078C9 !important;
}

.marquee {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
}
.marquee div {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 10s linear infinite;
}
@keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}