.product-form-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .product-form-card {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .product-form-card .card-title {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .product-form-card .form-group {
    margin-bottom: 15px;
  }
  
  .product-form-card .form-label {
    display: block;  
    text-align: left;  
    margin-bottom: 5px;  
    margin-top: 5px;
  }
  
  .product-form-card .form-control {
    border-radius: 5px;
  }
  
  .product-form-card .btn-primary {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
  }
  .submit-btn {
    background-color: #0078C9 !important;
  }