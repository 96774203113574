.display-text-container {
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
 
  .display-text-card {
    width: 100%;
    max-width: 1000px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: rgb(212, 228, 228);
  }
  
  .display-text-card p {
    text-align: justify; /* Optional: makes text justification more readable */
  }
/* Enroll now button styles */
.enroll-now {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 20px;
}

.enroll-now-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: #1f5e95;
    color: white;
    transition: background-color 0.3s ease;
}

.enroll-now-button:hover {
    background-color: #0056b3;
}
/* Key highlights */
.key-highlights {
    margin-bottom: 30px;
}

.key-highlights h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.highlight-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.highlight-item {
    flex: 1 1 calc(25% - 20px);
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    color: #000000;
}

.highlight-item:hover {
    transform: scale(1.05);
}

.highlight-item p {
    margin-top: 10px;
    font-size: 1.1em;
    color: #000000;
}  
.course-overview {
    text-align: justify;
}
/* Java card styles */
.java-card {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.java-card h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.java-card p {
    font-size: 1.1em;
    color: #555;
}

.java-card .rating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.java-card .rating p {
    margin: 0 5px;
    font-size: 1.2em;
    color: #333;
}

.java-card .stars {
    color: #f39c12;
}

.java-card .actions {
    margin-top: 20px;
}

.java-card .actions button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s;
}

.java-card .actions button:hover {
    background-color: #0056b3;
}

.course-objectives-container {
    margin-top: 20px;
    background-color: rgb(212, 228, 228);
  }
 
  .custom-switch .form-check-input {
    width: 50px;
    height: 30px;
    border-radius: 15px;
  }
  
  .custom-switch .form-check-label {
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .switch-label {
    font-size: 18px;
    margin: 0 15px;
  }
  
  .basic-java-card {
    margin-top: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 1000px;
    margin-bottom: 50px;
   
  }
  .course-objective {
    padding-top: 30px;
  }
  .basic-java-card hr {
    margin: 20px 0;
  }
  
  .basic-java-card .form-group {
    margin-bottom: 20px;
  }
  