.logotextchange{
  
    background-image: linear-gradient(
      to right,
      #F05A28,
      #F05A28 50%,
      #000 50%
    );
    background-size: 200% 100%;
    background-position: -100%;
    display: inline-block;
    /* padding: 5px 0; */
    position: relative;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s ease-in-out;
  }
  
  .logotextchange:before {
    content: '';
    background:  #0078C9;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 0;
    height: 3px;
    transition: all 0.3s ease-in-out;
    
  }
  
 .logotextchange:hover {
   background-position: 0;
  }
  
  .logotextchange:hover::before {
    width:100%;
  }
  .icon {
    color: #F05A28 !important;
    
  }
  .login-btn {
    background-color: #F05A28 !important;
    color: #FFFFFF;
  }
  .btn-circle {
    background-color: white; /* White background */
    border-radius: 40%; /* Makes the background rounded */
    padding: 10px; /* Adjust padding as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  