.card1{
    margin-top: 50px;
    border: none;
}
.label {
    text-align: left;
}
.title{
    margin-top: 30px;
}
.card2{
    border: none;
    margin-top: 30px;
    margin-bottom: 20px;
   
}
.card3{
    border: none;
    margin-top: 30px;
    margin-bottom: 20px;
   
}
.facebook{
    color: blue;
}