.enquiry-form-container {
  margin-top: 50px;
}

.shadow {
  box-shadow: 0 4px 8px rgba(5, 75, 241, 0.1);
}

.bg-white {
  background-color: #fff;
}

.rounded {
  border-radius: 10px;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.w-100 {
  width: 100%;
}


.Container {
  margin-top: 50px;
}

.Card {
  margin: 0 auto;
}

.Form {
  margin: 20px 0;
}
 .form-label {
  display: block;  
  text-align: left;  
  margin-bottom: 5px;  
  margin-top: 5px;
}
